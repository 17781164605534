import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <section className="section">
      <div className="columns is-centered">
        <div className="column is-8-tablet is-half-desktop pad-30">
          <p className="has-text-centered">
            De gevraagde pagina werd niet gevonden.
            <br />
            Gebruik bovenstaand menu om een keuze te maken.
          </p>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
